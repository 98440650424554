<template>
  <div class="course-list">
    <div class="header">
      <headerTerm :toPath="toPath"></headerTerm>
    </div>
    <div class="main">
      <div class="hot-text">
        热门课程
      </div>
      <div class="card-block">
        <courseList :courseList="courseList" :myCourse="false"></courseList>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <!-- <headerMask></headerMask> -->
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import headerTerm from "@/components/headerTerm.vue";
import headerMask from "@/components/headerMask.vue";
import footerMenu from "@/components/footerMenu.vue";
import courseList from "@/components/courseList.vue";
import latestPlay from "@/components/latestPlay.vue";
import { courseApi } from "@/api/courseApi.js";
export default {
  components: {
    headerTerm,
    footerMenu,
    courseList,
    // latestPlay,
    // headerMask, // 可以打开这里看遮挡效果
  },
  data() {
    return {
      toPath: "/",
      termId: 1,
      courseList: [],
    };
  },
  methods: {
    getCourseList() {
      courseApi.term(this.termId, 0, 99999).then((res) => {
        if (res.code == 0) {
          console.log("getCourseList", res);
          this.courseList = res.datas;
        }
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.termId = this.$route.query.id;
    } else {
      this.termId = 1;
    }
    this.getCourseList(this.termId);
  },
  watch: {
    $route(to, from) {
      if (to.query && to.query.id && to.query.id != from.query.id) {
        this.termId = to.query.id;
        this.getCourseList(this.termId);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.course-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    margin: 0 0 4px 0;
    flex: 0 0 auto;
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    .recom-play {
      margin: 0 8px 5px 8px;
    }
    .hot-text {
      margin: 8px 17px 4px 17px;
      font-size: 19px;
      color: #323233;
      letter-spacing: 0.42px;
      font-weight: 600;
    }
    .latest-play {
      margin: 0 5px 0px 5px;
      padding: 0;
    }
    .card-block {
      margin: 0 17px;
      flex: 1 1 auto;
      min-height: 300px;
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
